<template>
  <div>
    <b-sidebar
      id="sidebar-recognition-handler"
      sidebar-class="sidebar-lg"
      :visible="isRecognitionHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-recognition-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('add_recognition') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.date')"
              rules="required"
            >

              <b-form-group
                :label="$t('label.date')"
                label-for="date"
              >
                <flat-pickr
                  v-model="recognitionLocal.date"
                  class="form-control"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Worker -->
            <validation-provider
              #default="validationContext"
              :name="$t('message.worker')"
              rules="required"
            >
              <b-form-group
                :label="$t('message.worker')"
                label-for="worker"
                :description="recognitionLocal.worker ? `${$t('label.Location')}: ${recognitionLocal.worker.locations.map(e => e.location).join(' / ')}` : null"
              >
                <v-select
                  v-model="recognitionLocal.worker"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="commitmentFunctionality ? workersTotalNotMapped : workersSupervisedNotMapped"
                  label="name"
                  class="worker-selector"
                  input-id="worker"
                >

                  <template #option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name }">
                    <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Motive -->
            <validation-provider
              #default="validationContext"
              :name="$t('message.motive')"
              rules="required"
            >

              <b-form-group
                :label="$t('message.motive')"
                label-for="motive"
              >
                <v-select
                  v-model="recognitionLocal.motive"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="motives"
                  input-id="motive"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Note -->
            <validation-provider
              #default="validationContext"
              :name="$t('message.note')"
              rules="required"
            >
              <b-form-group
                :label="$t('message.note')"
                label-for="note"
              >
                <b-form-input
                  id="note"
                  v-model="recognitionLocal.note"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('message.add') }}
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('message.reset') }}
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted, watch, ref } from '@vue/composition-api'
import useRecognitionHandler from './useRecognitionHandler'
import useCommon from '@/views/organization/useCommon'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isRecognitionHandlerSidebarActive',
    event: 'update:is-recognition-handler-sidebar-active',
  },
  props: {
    isRecognitionHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    recognition: {
      type: Object,
      required: true,
    },
    clearRecognitionData: {
      type: Function,
      required: true,
    },
    motives: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      recognitionLocal,
      resetRecognitionLocal,

      // UI
      onSubmit,
    } = useRecognitionHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetRecognitionLocal, props.clearRecognitionData)

    const { getWorkersSupervisedForDropDown, workersSupervisedNotMapped, getWorkersTotalForDropDown, workersTotalNotMapped} = useCommon()

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality

    onMounted(async () => {
      if (commitmentFunctionality) getWorkersTotalForDropDown("notMapped")
      else getWorkersSupervisedForDropDown("notMapped")
    })

    return {
      // Add New
      recognitionLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Workers dropdown
      workersSupervisedNotMapped,
      workersTotalNotMapped,

      // Others
      commitmentFunctionality,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.worker-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

</style>
