// import axios from '@axios'
// import { getImprovements, addImprovement, updateImprovement } from '@/@core/queries/improvements'
// import store from '@/store'

export default {
  namespaced: true,
  state: {
    selectedLocations: [],
    selectedWorkers: [],
    selectedDueDates: "",
    selectedInstances: [],
    selectedInstanceLeaders: [],
    selectedZones: [],
    clientHasMetadata: false,
    optionsMetadata: {},
    toggleTaskHandlerSidebar: false,
    statisticCardsData: {},
  },
  getters: {},
  mutations: {
    SET_SELECTED_LOCATIONS(state, val) {
      state.selectedLocations = val
    },
    SET_SELECTED_WORKERS(state, val) {
      state.selectedWorkers = val
    },
    SET_SELECTED_DUE_DATES(state, val) {
      state.selectedDueDates = val
    },
    SET_SELECTED_INSTANCES(state, val) {
      state.selectedInstances = val
    },
    SET_SELECTED_INSTANCE_LEADERS(state, val) {
      state.selectedInstanceLeaders = val
    },
    SET_SELECTED_ZONES(state, val) {
      state.selectedZones = val
    },
    SET_CLIENT_HAS_METADATA(state, val) {
      state.clientHasMetadata = val
    },
    SET_OPTIONS_METADATA(state, val) {
      state.optionsMetadata = val
    },
    SET_TOGGLE_TASK_HANDLER_SIDEBAR(state, val) {
      state.toggleTaskHandlerSidebar = val
    },
    SET_STATISTIC_CARDS_DATA(state, val) {
      state.statisticCardsData = val
    },
  },
  actions: {
    // fetchTasks(ctx, {category, tag, locations, workers, dueDates, instances, instanceLeaders, zones}) {
    //   const getUserData = store.state?.userStore?.userData
    //   const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
    //   const now = new Date()
    //   const query = {
    //     client_id: (getUserData.role != 'admin') ? { _id: getUserData?.client?.$oid} : null,
    //     OR: [{ deleted: false }, { deleted_exists: false }],
    //   }

    //   // Category
    //   if (category !== "all") {
    //     if (category === "pending" || category === "overdue") {
    //       delete query.OR
    //       query.AND = [
    //         {OR: [{ deleted: false }, { deleted_exists: false }]},
    //         {OR: [{ completed: false }, { completed_exists: false }]}
    //       ]
    //       query.AND[2] = category === "pending"
    //         ? {OR: [{ dueDate_gte: now }, { dueDate_exists: false }]}
    //         : {dueDate_lt: now}
    //     }
    //     else {
    //       query[category] = true
    //       if (category === "deleted") delete query.OR
    //     }
    //   }

    //   // Tag
    //   if (tag !== "all") query.tags_in = tag

    //   // Location
    //   if (locations.length > 0) {
    //     const locationsFilterQuery = locations.map(e => {
    //       return {
    //         locations_in: {
    //           _id: e
    //         }
    //       }
    //     })
    //     query.assignee = { OR: locationsFilterQuery }
    //   }

    //   // Assignee
    //   if (workers.length > 0) {
    //     if (query.assignee) query.assignee._id_in = workers
    //     else query.assignee = {_id_in: workers}
    //   }

    //   // User logged as supervisor
    //   if (getUserData.role === 'supervisor') {
    //     if (query.OR) {
    //       delete query.OR
    //       query.AND = [
    //         {OR: [{ deleted: false }, { deleted_exists: false }]},
    //         {OR: [{ assignee: { supervisors_in: {_id: userId} } }, { assignee: {_id: userId} }]}
    //       ]
    //     }
    //     else if (query.AND) {
    //       query.AND.push({OR: [{ assignee: { supervisors_in: {_id: userId} } }, { assignee: {_id: userId} }]})
    //     }
    //     else {
    //       query.OR = [{ assignee: { supervisors_in: {_id: userId} } }, { assignee: {_id: userId} }]
    //     }
    //   }

    //   // Due date
    //   if (dueDates[0]) {
    //     query.dueDate_gte = dueDates[0]
    //     query.dueDate_lt = dueDates[1]
    //   }

    //   // Instance
    //   if (instances.length) {
    //     if (query.AND) query.AND.push({ metadata: { name_in: "instance", answer_in: instances } })
    //     else query.AND = [{ metadata: { name_in: "instance", answer_in: instances } }]
    //   }

    //   // Instance leader
    //   if (instanceLeaders.length) {
    //     if (query.AND) query.AND.push({ metadata: { name_in: "instance_leader", answer_in: instanceLeaders } })
    //     else query.AND = [{ metadata: { name_in: "instance_leader", answer_in: instanceLeaders } }]
    //   }

    //   // Zone
    //   if (zones.length) {
    //     if (query.AND) query.AND.push({ metadata: { name_in: "zone", answer_in: zones } })
    //     else query.AND = [{ metadata: { name_in: "zone", answer_in: zones } }]
    //   }
      
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', {
    //         query: getImprovements,
    //         variables: {
    //           query,
    //           limit: 10000,
    //           sortBy: "DUEDATE_ASC"
    //         },
    //       })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // addTask(ctx, taskData) {
    //   if (taskData.assignee) {
    //     taskData.assignee = { link: taskData.assignee._id }
    //   }

    //   taskData.dueDate = taskData.dueDate ? new Date(`${taskData.dueDate.slice(0, 10)} 12:00:00`) : null

    //   if (taskData.metadata && taskData.metadata.length) {
    //     taskData.metadata = taskData.metadata.map(metadata => ({
    //       name: metadata.name,
    //       type: metadata.type,
    //       answer: metadata.answer,
    //     }))
    //   }

    //   if (taskData.imgData) delete taskData.imgData

    //   if (taskData.subscribers?.length) taskData.subscribers = { link: taskData.subscribers }
    //   else delete taskData.subscribers

    //   // taskData.origin = "improvement"

    //   const query = {
    //     query: addImprovement,
    //     variables: { data: taskData },
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', query)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // updateTask(ctx, taskData) {
    //   let taskCopy = {...taskData}

    //   if (taskCopy.assignee) taskCopy.assignee = { link: taskCopy.assignee._id }

    //   taskCopy.dueDate = taskCopy.dueDate ? new Date(`${taskCopy.dueDate.slice(0, 10)} 12:00:00`) : null

    //   if (taskCopy.subscribers) taskCopy.subscribers = { link: taskCopy.subscribers }
    //   else delete taskCopy.subscribers

    //   const query = {
    //     query: updateImprovement,
    //     variables: {
    //       query: { _id: taskCopy._id },
    //       data: taskCopy,
    //     },
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/graphql', query)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // removeTask(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete(`/apps/todo/tasks/${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
