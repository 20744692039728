<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Demo user section -->
    <b-navbar
      v-if="isDemoUser"
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar-shadow align-items-center d-block fixed-top"
      style="min-height: 3rem; padding-top: 0.3rem;"
      >
      <slot
        name="demo-section"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <div
          class="d-flex ml-2 ml-md-3"
          style="margin-top: .5rem; margin-bottom: .3rem;">

          <!-- Trial version message and Delete demo data-->
          <small
            class="d-block d-md-none text-primary mr-2 text-left"
            style="text-align: left;">
              {{ $t('message.trialVersionMessage') }}
              <small class="text-danger font-weight-bolder">{{ daysUntilExpiration }}</small>
              {{ $t('message.days') }}.
              <small
                class="text-danger font-weight-bolder"
                style="text-decoration: underline; text-underline-offset: 0.2em;"
                v-b-modal="'deleteDemoDataModal'">
                {{ $t('message.deleteTestData') }}
              </small>
              &nbsp;
              <small style="font-weight: 600">   
                <b-link
                  to="/habit/contact-us"
                  target="_blank"
                  style="text-decoration: underline; text-underline-offset: 0.2em;"
                >{{ $t('message.contactUs') }}
                </b-link>
              </small>
          </small>
          <span
            class="d-none d-md-block text-primary mr-2 text-start"
            style="align-content: center;">
              {{ $t('message.trialVersionMessage') }}
              <span class="text-danger font-weight-bolder">{{ daysUntilExpiration }}</span>
              {{ $t('message.days') }}
          </span>
          <span
            class="d-none d-md-block text-danger font-weight-bolder mr-2 text-start"
            style="text-decoration: underline; text-underline-offset: 0.2em;"
            v-b-modal="'deleteDemoDataModal'">
            {{ $t('message.deleteTestData') }}
          </span>
          <span class="d-none d-md-block text-primary ml-auto mr-3 text-end font-weight-bolder">
            <b-link
              to="/habit/contact-us"
              target="_blank"
              style="text-decoration: underline; text-underline-offset: 0.2em;"
            >{{ $t('message.contactUs') }}</b-link>
          </span>

          <b-modal
            id="deleteDemoDataModal"
            centered
            no-close-on-backdrop
            modal-class="modal-danger"
            ok-variant="danger"
            cancel-variant="outline-secondary"
            :title="$t('message.confirm_action')"
            :ok-title="$t('message.delete')"
            :cancel-title="$t('message.cancel')"
            @ok="deleteTestData"
          > {{ $t('message.confirm_delete_demo_data') }}.
            <br>
            <small class="text-secondary">{{ $t('message.explanation_delete_demo_data') }}</small>
          </b-modal>

          <loading
            v-if="isLoading"
            :active="true" 
            :is-full-page="true"
            :color="colors.primary"
          />
        </div>
      </slot>
    </b-navbar>

    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center d-block"
      :class="[navbarTypeClass, { 'mt-5': isDemoUser }]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <!-- Client Logo -->
        <div v-if="isImageAvailable" class="d-block d-md-none align-items-center mb-0 mt-1 ml-2" :class="mode === 'dark' ? 'dark-mode' : 'light-mode'">
          <img
            :src="require(`@/assets/images/clients_logo/${imageName}`)"
            alt="logo"
            class="img-fluid"
          />
        </div>
        <app-navbar-vertical-layout
          :toggle-vertical-menu-active="toggleVerticalMenuActive"
          :isImageAvailable="isImageAvailable"
          :imageName="imageName"
          :mode="mode"
        />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted, ref, computed, watch, onMounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BButton, BModal, VBModal, BLink } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import realmConnection from '@/views/habit/realm'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'
import useJwt from '@/auth/jwt/useJwt'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    BButton,
    BModal,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    Loading,
    BLink
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    const { invokeFunction } = realmConnection()

    const { project, client, worker_id, expirationDate } = JSON.parse(localStorage.getItem('userData') || '{}')
    const isDemoUser = project?.startsWith("demoUser")
    const isLoading = ref(false)
    const { skin } = useAppConfig()
    const mode = ref(skin.value)
    const daysUntilExpiration = ref('...')
    const clientDataName = JSON.parse(localStorage.getItem("clientData") || '{}').name
    const imageName = clientDataName?.toLowerCase() + '.png'
    const { router } = useRouter()
    
    const isImageAvailable = computed(() => {
      try {
        require(`@/assets/images/clients_logo/${imageName}`)
        return true
      } catch (e) {
        return false
      }
    })

    watch(skin, () => {
      mode.value = skin.value
    })

    const calculateDaysUntilExpiration = () => {
      const parseExpirationDate = new Date(parseInt(expirationDate.$date.$numberLong, 10))
      const today = new Date()
      // Convert time difference from milliseconds to days
      const daysDiff = Math.ceil((parseExpirationDate - today) / (1000 * 60 * 60 * 24))
      daysUntilExpiration.value = daysDiff
      if(daysUntilExpiration.value <= 0) logout()
    }

    const logout = async () => {
      await useJwt.logout()
      // Remove tokens
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // Remove userData and clientData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('clientData')
      // Reset ability
      router.push({ name: 'auth-login' })
    }
    
    onMounted(() => {
      if (isDemoUser) calculateDaysUntilExpiration()
    })

    const deleteTestData = async () => {
      isLoading.value = true

      try {
        await invokeFunction({ name: 'deleteDemoDataByUser', arg: { clientId: client.$oid, workerId: worker_id.$oid } })
        location.reload()
      } catch (error) {
        console.log(error)
        isLoading.value = false
      }
    }

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,
      isDemoUser,
      deleteTestData,
      colors,
      isLoading,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,

      imageName,
      isImageAvailable,
      mode,
      daysUntilExpiration,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>

<style scoped>
.light-mode {
  max-width: 110px;
  opacity: 0.6;
}
.dark-mode {
  max-width: 110px;
  opacity: 0.6;
  filter: brightness(0) saturate(100%) invert(1);
}
</style>
