<template>
  <b-nav-item @click="$emit('add-recognition')">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="flat-primary"
      v-b-tooltip.hover.v-primary.bottom="$t('add_recognition')"
      class="btn-icon rounded-circle ml-0"
    >
      <feather-icon
        size="19"
        icon="StarIcon"
      />
    </b-button>
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BNavItem,
  },
}
</script>
