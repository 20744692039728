import { ref, watch } from '@vue/composition-api'

export default function useRecognitionHandler(props, emit) {
  // ------------------------------------------------
  // Recognition
  // ------------------------------------------------
  const recognitionLocal = ref(JSON.parse(JSON.stringify(props.recognition.value)))
  const resetRecognitionLocal = () => {
    recognitionLocal.value = JSON.parse(JSON.stringify(props.recognition.value))
  }
  watch(props.recognition, () => {
    resetRecognitionLocal()
  })

  const onSubmit = () => {
    const recognitionData = JSON.parse(JSON.stringify(recognitionLocal))

    emit('add-recognition', recognitionData.value)

    // Close sidebar
    emit('update:is-recognition-handler-sidebar-active', false)
  }

  return {
    recognitionLocal,
    resetRecognitionLocal,

    // UI
    onSubmit,
  }
}
